import jwtDecode from "jwt-decode";
import { setCookie } from "@baggie/core";
import { getDomainName } from "./getDomainName.helper";

export const setCustomerNumberCookie = (accessToken: string, customerNumber: string) => {
    const jwtData = jwtDecode(accessToken) as {
        oid?: string;
    };
    if ("oid" in jwtData) {
        setCookie(`customer.${jwtData.oid}`, customerNumber, {
            path: "/",
            domain: getDomainName(),
        });
    }

    if (location.hostname === "localhost") {
        setCookie("localhost-last-customer", customerNumber);
    }
};
